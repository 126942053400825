<template>
  <div :class="['search-input', { 'auto-comp': autoComplete }]">
    <div :class="['search-input-box', { mr8: !autoComplete }]">
      <input
        ref="searchInput"
        :value="value"
        class="search-input_input"
        type="text"
        :placeholder="placeholder"
        @input="onInput"
        @keypress.enter="onEnter"
      />
      <div v-if="autoComplete && value.length" class="auto-complete">
        <ul v-if="autoCompleteData.length">
          <li v-for="(data, index) in autoCompleteData" :key="'auto-complete-' + index" @click="keywordChoice(data)">
            <span v-for="(char, idx) in data.channelTitle" :key="char + idx" :class="{ match: value.includes(char) }">{{
                char
              }}
            </span>
          </li>
        </ul>
        <div v-else class="none-data">
          검색 결과가 없습니다.
        </div>
      </div>
    </div>
    <button v-if="isSearchInputFilled" class="search-input_button--reset fs0" @click="onDeleteSearchInput">
      <img class="opacity5" src="/img/pages/icon/ico-close-black.svg" alt="close" />
    </button>
    <button class="search-input_button--search fs0 disp-flex" @click="onClick">
      <img class="opacity5" src="/img/pages/icon/search_default-black.svg" alt="search" />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    autoComplete: {
      type: Boolean,
      default: false,
    },
    autoCompleteData: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isSearching: false,
  }),
  computed: {
    isSearchInputFilled() {
      return this.value !== '';
    },
  },
  methods: {
    onDeleteSearchInput() {
      this.$emit('input', '');
      this.$emit('click');
    },
    onClick() {
      this.$emit('click');
    },
    onEnter() {
      if (this.autoComplete && this.autoCompleteData.length) {
        const focusItem = Array.from(document.querySelectorAll('.auto-complete li')).filter(item => item.classList.contains('focus'));
        if (focusItem.length) {
          focusItem[0].click();
          return null;
        }
      }

      this.$emit('click');
    },
    onInput(e) {
      this.$emit('input', e.target.value);
    },
    keywordChoice(data) {
      this.$emit('keyword-choice', data);
    },
    focusListItem(keycode) {
      const autoCompleteEl = document.querySelector('.auto-complete ul');
      const items = document.querySelectorAll('.auto-complete li');
      let focusItemIndex = -1;
      let nextFocusIndex = 0;

      if (!items.length) return null;

      for (let i = 0; i < items.length; i++) {
        if (items[i].classList.contains('focus')) {
          focusItemIndex = i;

          if (keycode === 40) {
            nextFocusIndex = i + 1 < items.length ? i + 1 : 0;
            if (nextFocusIndex) {
              if (
                autoCompleteEl.offsetHeight - items[nextFocusIndex].offsetHeight
                < items[nextFocusIndex].offsetHeight * nextFocusIndex
              ) {
                autoCompleteEl.scrollTop += items[nextFocusIndex].offsetHeight;
              }
            } else {
              autoCompleteEl.scrollTop = 0;
            }
          } else if (keycode === 38) {
            nextFocusIndex = i - 1 >= 0 ? i - 1 : items.length - 1;
            if (nextFocusIndex !== items.length - 1) {
              if (autoCompleteEl.scrollTop > items[nextFocusIndex].offsetHeight * nextFocusIndex) {
                autoCompleteEl.scrollTop -= items[nextFocusIndex].offsetHeight;
              }
            } else {
              autoCompleteEl.scrollTop = items[nextFocusIndex].offsetHeight * nextFocusIndex;
            }
          }
          break;
        }
      }

      if (focusItemIndex !== -1) items[focusItemIndex].classList.remove('focus');

      items[nextFocusIndex].classList.add('focus');
      items[nextFocusIndex].focus();
    },
  },
  mounted() {
    if (this.autoComplete) {
      this.$refs.searchInput.addEventListener('keydown', e => {
        if (e.keyCode === 38 || e.keyCode === 40) {
          this.focusListItem(e.keyCode);
          e.preventDefault();
        }
      });
    }
  },
};
</script>

<style scoped lang="less">
@import '~@/less/proj.less';

.search-input {
  .flex;
  .rel;
}
.search-input-box {
  .rel;
  width: calc(100% - 48px);
}

.search-input_input[type='text'] {
  .wh(100%, 40);
  .br(50);
  .bgc(#ebebf0);
  .p(10, 15);
  .pr(15);
  .c(#191919);
  .-a(transparent, 0);
}

input[type='text']::placeholder {
  .c(#a4a3ae);
}
.search-input_button--reset {
  .abs;
  .rt(41, 0);
  z-index: 1;
  .wh(40);

  .opacity5 {
    .wh(12);
    .o(0.5);
  }
  &:hover {
    .opacity5 {
      .o(1);
    }
  }
}

.auto-comp {
  .search-input-box {
    .w(100%);
  }

  .search-input_input[type='text'] {
    .pr(70);
  }

  .search-input_button--reset {
    .r(34);
  }
}

.search-input_button--search {
  .wh(40);
  .br(50%);
  .bgc(#fff);
  .-a(#ebebf0, 2);
  .flex-jc(center);

  .opacity5 {
    .wh(18);
    .o(0.5);
  }
  &:hover {
    .opacity5 {
      .o(1);
    }
  }
}

.auto-comp {
  .search-input_button--search {
    .abs;
    .rt(0, 0);
    z-index: 1;
    .h(40);
  }
}

.auto-complete {
  .abs;
  .lt(0, 45);
  z-index: 1;
  .w(100%);
  .p(0, 10);
  .bgc(#fff);
  .br(12);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);

  ul {
    .max-h(300);
    overflow: auto;

    > li {
      .p(8, 6);
      .c(rgba(0, 0, 0, 0.5));
      .-b(rgba(0, 0, 0, 0.1));
      .pointer;

      &.focus {
        .bgc(#f9f9f9);
      }

      &:hover {
        .bgc(#f9f9f9);
      }

      &:last-child {
        border-bottom: 0;
      }

      > span {
        &.match {
          .c(#2c81ff);
          text-decoration: underline;
        }
      }
    }
  }

  .none-data {
    .tc;
    .p(53, 0);
    .fs(15);
    .c(rgba(0, 0, 0, 0.3));
  }
}
</style>
