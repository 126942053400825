<template>
  <PItem community-game-card :class="{ lg }">
    <div class="game-card-con" @click="moveToArena">
      <div class="img-con">
        <ListCardThumb :image="image || '/img/asset/Poster_Default.jpg'" :status-tag="item.statusTag"
          :state="item.state" :schedule="item.schedule" />
      </div>
      <div class="info-holder">
        <div class="info-box">
          <h6 class="title">
            {{ title }}
            <span v-if="lg" class="sponsor-label">SPONSORED</span>
          </h6>
          <div class="sub-info">
            <em class="time"> {{ startTime }} KST </em> <em class="mode"> {{ modeSize }} </em> <br /> <em class="date"> {{ startDateFormat }} </em>
            <CountDown v-if="lg && showCountDown" :end-time="item.schedule.start" />
          </div>
        </div>
      </div>
      <div class="mb-sub-info">
        <em class="time"> {{ startTime }} KST </em> <em class="mode"> {{ modeSize }} </em> <br /> <em class="date"> {{ startDateFormat }} </em>
        <CountDown v-if="lg && showCountDown" :end-time="item.schedule.start" />
      </div>
    </div>
  </PItem>
</template>

<script>
import { customDateFormat, remainDate } from '@shared/utils/timeUtils';
import { historyMove } from '@shared/utils/routerUtils';
import { MAIN_URL } from '@/env';
import ListCardThumb from '@/views/components/common/ListCardThumb.vue';
import CountDown from '@/views/components/common/CountDown.vue';

export default {
  name: 'CommunityGameCard',
  components: { ListCardThumb, CountDown },
  props: {
    lg: Boolean,
    item: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    image() {
      const images = {
        SAMPLE_IMAGE1: '/img/asset/Poster_Default.jpg',
        SAMPLE_IMAGE2: '/img/asset/Poster_Default2.jpg',
      };

      return this.item.posterType !== 'CUSTOM_IMAGE' ? images[this.item.posterType] : this.item.images.lg || this.item.images.logo || this.item.images.md || this.item.images.sm;
    },
    backgroundColor() {
      return this.imageObj.backGroundColor;
    },
    title() {
      return this.item.title || '';
    },
    state() {
      const stateVal = this.item.state;
      switch (stateVal) {
        case 'PRE':
          return '준비중';

        case 'PLAYOFF':
          return '진행중';

        case 'END':
          return '종료';

        default:
          return '-';
      }
    },
    modeSize() {
      const size = this.item.management.mode;
      return `${size} V ${size}`;
    },
    startDateFormat() {
      return customDateFormat(this.item.schedule.start, 'MM월 DD일');
    },
    startMonth() {
      return customDateFormat(this.item.schedule.start, 'MM월');
    },
    startDay() {
      return customDateFormat(this.item.schedule.start, 'DD');
    },
    startTime() {
      return customDateFormat(this.item.schedule.start, 'HH:mm');
    },
    endDateFormat() {
      return customDateFormat(this.item.schedule.end, 'YYYY.MM.DD');
    },
    remainStartTime() {
      const diffDay = remainDate(this.$store, this.item.schedule.start);
      return diffDay < 10 ? Number(diffDay.toString().padStart(2, '0')) : diffDay;
    },
    showCountDown() {
      if (this.lg && this.item.schedule) {
        if (this.remainStartTime < 4 && this.remainStartTime >= 0) return true;
      }
      return false;
    },
  },
  methods: {
    moveToArena() {
      historyMove(`${MAIN_URL}/arena/${this.$route.params.id}/${this.item.id}/info`);
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[community-game-card] {.fl;.w(100%);.mb(12);.bgc(#fff);.br(8);.p(14, 0);.mr(0); .o(0); .t-y(50); .pointer; transition: .5s;
  &:hover {
    .bgc(#f5f5f5);
  }
  .intro-up(0, {.t-y(0); .o(1);});
  .game-card-con {.rel;
    .img-con {.abs;.lt(0, 0);z-index: 1;.wh(160, 99);
      > div {.rel;.h(100%);
        p {
          &:nth-child(1) {.abs;.lt(10, 10);z-index: 1;.p(3.5, 7.5);.fs(12);.c(#5b91f5);.bgc(#d4e2fc);border: 1px solid rgba(84, 132, 222, 0.1);.br(4);
          }
          &:nth-child(2) {border-radius: 0px 4px 4px 0px;}
        }
      }
    }
    .info-holder {.pl(172);.pr(20);.h(99);.crop;.tl;
      .info-box {.rel;.h(100%);
        .title {.fs(20, 24px);.bold;.mt(20);.ellipsis(2); .ls(-1);
          .sponsor-label {.ib;.wh(89, 24);.p(3.5, 0);.ml(5);.fs(12); line-height: 1;.regular;.c(#fff);.tc;.vam;.br(4);.bgc(#d13639);border: 1px solid rgba(230, 195, 191, 0.1);
          }
        }
        .sub-info {.hide;.abs;.lb(0, 15);z-index: 1;.o(0.7);
          > br {.hide;}

          > em {.rel;.pl(28);.bg-s(20, 20);.no-repeat;
            &:last-child {
              &::after {.hide;}
            }

            &::after {content: '';.ib;.m(-2, 8);.wh(1, 15);.bgc(rgba(0, 0, 0, 0.2));}
            &.time {.bg('@{icon}/Arena_access_time.svg');}
            &.mode {.bg('@{icon}/Arena_mode.svg');}
            &.date {.bg('@{icon}/Arena_date.svg');}
          }
        }
      }
    }

    .mb-sub-info {.block;.p(15, 15);.pb(4);.fs(14);.tl;.o(0.7);
      > br { .hide;}

      > em {.ib;.rel;.pl(21);.mt(5);.no-repeat;background-size: 16.67px;background-position: left center;
        &:last-child {
          &::after {.hide;}
        }

        &::after {content: '';.ib;.m(-2, 8);.wh(1, 15);.bgc(rgba(0, 0, 0, 0.2));}
        &.time {.bg('@{icon}/Arena_access_time.svg');}

        &.mode {.bg('@{icon}/Arena_mode.svg');}

        &.date {.bg('@{icon}/Arena_date.svg');}
      }
    }
  }

  &.lg {
    float: none;
    .w(100%);
    .game-card-con {
      .img-con {.abs;.lt(0, 0);z-index: 1;.wh(160, 99);
        > div {
          p {
            &:nth-child(1) {.lt(10, 10);.p(3.5, 7.5);}
          }
        }
      }

      .info-holder {.pl(172);.h(99);
        .info-box {
          .title {.fs(20);.mt(5);}
          .sub-info {.b(49);.hide;
            > br {.block;}
            > em {.ib;.mt(15);
              &::after {.m(-2, 20);}

              &:nth-child(even) {
                &::after {.hide;}
              }
            }
          }
        }
      }

      .mb-sub-info {.p(5, 20);
        > br {.block;}
        > em {.ib;.fs(16);.pl(28);.mt(10); background-size: 20px;
          &::after {.m(-2, 20);}
          &:nth-child(even) {
            &::after {.hide;}
          }
        }
      }
    }
  }

  @media (@tl-up) {
    .mb(20); .mr(0);
    // width: calc(50% - 20px);
    .game-card-con {
      .img-con {.wh(240, 148);}
      .info-holder { .pl(260); .h(148);
        .info-box {
          .sub-info { .block; .b(27);}
        }
      }
      .mb-sub-info { .hide; }
    }

    &.lg {float: none;.w(100%);
      .game-card-con {
        .img-con {.wh(320, 198);}
        .info-holder { .pl(350); .h(198);
          .info-box {
            .title { .fs(24); .ls(-1);}
            .sub-info { .block; }
          }
        }
      }
    }
  }

  @media (@ds-up) {
    width: calc(50% - 10px); .mb(20);
    .game-card-con {
      .img-con {.wh(240, 148);}
      .info-holder {
        .info-box {
          .sub-info { .block; }
        }
      }
    }
  }
}
</style>
