<template>
  <div scheleton-community-game-card>
    <div class="game-card-con">
      <div class="img-con">
      </div>
      <div class="infor-con">
        <div class="info-box">
          <h6 class="title">

          </h6>
          <div class="sub-info">
            <em class="time"> </em> <em class="mode"> </em> <br /> <em class="date"> </em>
          </div>
        </div>
      </div>
      <div class="mb-sub-info">
        <em class="time"> </em> <em class="mode"> </em> <br /> <em class="date"> </em>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ScheletonCommunityGameCard',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[scheleton-community-game-card] {
  opacity: 0.8;
  .fl;
  width: calc(50% - 20px);
  .mb(20);
  .bgc(#fff);
  .br(8);
  .p(14, 0);
  .mr(20);
  animation: schel-twincle 5s infinite;

  .game-card-con {
    .rel;

    .img-con {
      .abs;
      .lt(0, 0);
      z-index: 1;
      .wh(240, 100%);
      .bgc(#ddd);

      > div {
        .rel;
        .h(100%);

        p {

          &:nth-child(1) {
            .abs;
            .lt(12, 12);
            z-index: 1;
            .p(4.5, 8.5);
            .fs(12);
            .c(#5b91f5);
            .bgc(#d4e2fc);
            border: 1px solid rgba(84, 132, 222, 0.1);
            .br(4);
          }
          &:nth-child(2) {
            border-radius: 0px 4px 4px 0px;
          }
        }
      }
    }

    .infor-con {
      .pl(264);
      .pr(20);
      .h(148);
      .crop;
      .tl;

      .info-box {
        .rel;
        .h(100%);

        .title {
          .fs(24);
          .bold;
          .m(8, 0);
          .ellipsis(2);
          .bgc(#ddd);
          .h(35%);
          .br(3);

          .sponsor-label {
            .ib;
            .wh(89, 24);
            .p(3.5, 0);
            .ml(5);
            .fs(12);
            .regular;
            .c(#fff);
            .tc;
            .vam;
            .br(4);
            .bgc(#d13639);
            border: 1px solid rgba(230, 195, 191, 0.1);
          }
        }

        .sub-info {
          .abs;
          .lb(0, 15);
          z-index: 1;
          .o(0.7);

          > br {
            .hide;
          }

          > em {
            .rel;
            .pl(28);
            .bg-s(20, 20);
            .no-repeat;
            background-image: none;
            .m(0, 5);
            .wh(80, 20);
            .bgc(#ddd);

            &:last-child {
              &::after {
                .hide;
              }
            }

            &::after {
              content: '';
              .hide;
              .m(-2, 8);
              .wh(1, 15);
              .bgc(rgba(0, 0, 0, 0.2));
            }
          }
        }
      }
    }

    .mb-sub-info {
      .hide;
    }
  }

  &.lg {
    float: none;
    .w(100%);
    .game-card-con {
      .img-con {
        .abs;
        .lt(0, 0);
        z-index: 1;
        .wh(320, 100%);
      }

      .infor-con {
        .pl(352);
        .h(198);

        .info-box {
          .title {
            .mt(21);
          }
          .sub-info {
            .b(49);

            > br {
              .block;
            }

            > em {
              .ib;
              .mt(15);

              &::after {
                .m(-2, 20);
              }

              &:nth-child(even) {
                &::after {
                  .hide;
                }
              }
            }
          }
        }
      }
    }
  }

  @media (@ds-down) {
    .w(100%);
    .mr(0);
  }

  @media (@tl-down) {
    .mb(12);
    .game-card-con {
      .img-con {
        .wh(160, 99);

        > div {
          p {
            &:nth-child(1) {
              .lt(10, 10);
              .p(3.5, 7.5);
            }
          }
        }
      }

      .infor-con {
        .h(99);
        .pl(172);

        .info-box {
          .title {
            .fs(20);
            .mt(8);

            > br {
              .hide;
            }
          }

          .sub-info {
            .hide;
          }
        }
      }

      .mb-sub-info {
        .block;
        .p(15, 15);
        .pb(4);
        .fs(14);
        .tl;
        .o(0.7);

        > br {
          .hide;
        }

        > em {
          .ib;
          .rel;
          .pl(21);
          .mt(5);
          .no-repeat;
          background-size: 16.67px;
          background-position: left center;
          background-image: none;
          .bgc(#ddd);
          .wh(40, 20);
          .m(0, 5);

          &:last-child {
            &::after {
              .hide;
            }
          }

          &::after {
            content: '';
            .hide;
            .m(-2, 8);
            .wh(1, 15);
            .bgc(rgba(0, 0, 0, 0.2));
          }
        }
      }
    }

    &.lg {
      .game-card-con {
        .img-con {
          .wh(160, 99);

          > div {
            p {
              &:nth-child(1) {
                .lt(10, 10);
                .p(3.5, 7.5);
              }
            }
          }
        }

        .infor-con {
          .h(99);
          .pl(172);

          .info-box {
            .title {
              .fs(20);
              .mt(5);
            }

            .sub-info {
              .hide;
            }
          }
        }

        .mb-sub-info {

          .p(5, 20);
          > br {
            .block;
          }
          > em {
            .ib;
            .fs(16);
            .pl(28);
            .mt(10);
            background-size: 20px;
            background-image: none;
            .bgc(#ddd);

            &:last-child {
              &::after {
                .hide;
              }
            }
            &:nth-child(even) {
              &::after {
                .hide;
              }
            }
          }
        }
      }
    }
  }

  @keyframes schel-twincle {
    0% {
      opacity: 0.7;
    }

    25% {
      opacity: 0.3;
    }

    50% {
      opacity: 0.7;
    }

    75% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.7;
    }
  }
}
</style>
